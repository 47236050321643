.loading {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FBDD65;
  z-index: 100;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  gap: 24px;
}