.root {
  position: relative;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.title {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
}


.body:not(:first-child) {
  margin-top: 0.5rem;
}

.body p {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.actions {
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;
}

.iconMain {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.iconClose {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.theme-info {
  background-color: #e9f3fc;
}

.theme-info .iconMain {
  fill: #1068bf !important;
}

.theme-warning {
  background-color: #fdf1dd;
}

.theme-warning .iconMain {
  fill: #9e5400 !important;
}