.header {
  height: 48px;
  background-color: #FBDD65;
  z-index: 1000;
  margin-bottom: 0;
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
}

.container-fluid {
  padding: 0 16px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.header-content {
  align-items: stretch;
  display: flex;
  gap: 0.5rem;
}

.logo {
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.logo img {
  width: 24px;
  height: 24px;
}