.view {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

@media (max-width: 640px) {
  .view {
    user-select: none;
  }
}

.icon {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 3rem;
  width: 3rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}

.icon>svg {
  height: 1.5rem;
  width: 1.5rem;
}

.h3 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  padding: 0;
  margin: 0;
}

.p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  margin: 0;
  padding: 8px 0 0;
}

.second {
  margin-left: 0.75rem;
  text-align: center;
}

@media (min-width: 640px) {
  .view {
    flex-direction: row;
  }

  .icon {
    margin-left: 0px;
    margin-right: 0px;
    height: 2.5rem;
    width: 2.5rem;
  }

  .second {
    margin-left: 1rem;
    margin-top: 0px;
    text-align: left;
  }
}


/* theme danger: */

.theme-danger .icon {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.theme-danger .icon>svg {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}


/* theme primary: */

.theme-primary .icon {
  --tw-bg-opacity: 1;
  background-color: #c8e0ff;
}

.theme-primary .icon>svg {
  --tw-text-opacity: 1;
  color: rgb(79 70 229/ var(--tw-text-opacity));
}