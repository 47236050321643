.footer {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media (min-width: 640px) {
  .footer {
    flex-direction: row-reverse;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .footer>button {
    width: auto;
  }
}