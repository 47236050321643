.tabs {
  border-bottom: 1px solid #dcdcde;
  display: flex;
}

ul.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-style: solid;
  border-color: #dcdcde;
  border-width: 0;
  border-bottom-width: 0;
  flex-grow: 1;
}

.nav-controls {
  display: inline-block;
  text-align: right;
}

@media (min-width: 768px) {
  .nav-controls {
    display: flex;
    align-items: center;
  }
}


a:hover {
  color: #1f75cb;
}

.nav-link {
  outline: none;
  border: 0;
  background: none;
  color: #333238;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition: box-shadow 100ms linear;
  text-decoration: none;
  cursor: pointer;
}


.nav-link:hover {
  color: #333238;
}

.nav-link:hover:not(.active) {
  box-shadow: inset 0 -2px 0 0 #bfbfc3;
}

.nav-link.active {
  font-weight: 600;
  color: #333238;
  box-shadow: inset 0 -2px 0 0 var(--gl-theme-accent, #6666c4);
}