.input {
  --tw-bg-opacity: 1;
  box-sizing: border-box;
  border-style: solid;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  font-family: inherit;
  font-weight: inherit;
  margin: 0;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  border-width: 0px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--jahoda-ring-offset-width) var(--jahoda-ring-offset-color);
  --jahoda-ring-shadow: var(--jahoda-ring-inset) 0 0 0 calc(1px + var(--jahoda-ring-offset-width)) var(--jahoda-ring-color);
  box-shadow: var(--jahoda-ring-offset-shadow), var(--jahoda-ring-shadow), var(--jahoda-shadow, 0 0 #0000);
  --jahoda-ring-inset: inset;
  --jahoda-ring-opacity: 1;
  --jahoda-ring-color: rgb(209 213 219 / var(--jahoda-ring-opacity));
  font-size: 0.875rem;
  line-height: 1.5rem;
  outline: none;
}

.input:focus {
  --jahoda-ring-opacity: 1;
  --jahoda-ring-color: rgb(79 70 229 / var(--jahoda-ring-opacity));
  --jahoda-ring-inset: inset;
  -jahoda-ring-offset-shadow: var(--jahoda-ring-inset) 0 0 0 var(--jahoda-ring-offset-width) var(--jahoda-ring-offset-color);
  --jahoda-ring-shadow: var(--jahoda-ring-inset) 0 0 0 calc(2px + var(--jahoda-ring-offset-width)) var(--jahoda-ring-color);
  box-shadow: var(--jahoda-ring-offset-shadow), var(--jahoda-ring-shadow), var(--jahoda-shadow, 0 0 #0000);
}

.textfield>div {
  font-variation-settings: normal;
  line-height: inherit;
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  --jahoda-bg-opacity: 1;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  --jahoda-ring-inset: ;
  --jahoda-ring-offset-width: 0px;
  --jahoda-ring-offset-color: #fff;
  --jahoda-ring-color: rgb(59 130 246 / 0.5);
  --jahoda-ring-offset-shadow: 0 0 #0000;
  --jahoda-ring-shadow: 0 0 #0000;
  --jahoda-shadow: 0 0 #0000;
  --jahoda-shadow-colored: 0 0 #0000;
  margin-top: 0.5rem;
}

.label {
  --jahoda-bg-opacity: 1;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  --jahoda-ring-inset: ;
  --jahoda-ring-offset-width: 0px;
  --jahoda-ring-offset-color: #fff;
  --jahoda-ring-color: rgb(59 130 246 / 0.5);
  --jahoda-ring-offset-shadow: 0 0 #0000;
  --jahoda-ring-shadow: 0 0 #0000;
  --jahoda-shadow: 0 0 #0000;
  --jahoda-shadow-colored: 0 0 #0000;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}