.loading {
  color: #535158;
  font-size: 32px;
  text-align: center;
}

.container {
  line-height: 0;
  text-align: center;
}

.spinner {
  vertical-align: text-bottom !important;
  animation-name: spin;
  animation-duration: 1s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  position: relative;
  display: inline-flex;
  border-radius: 50%;
  border-style: solid;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  border-color: rgba(83, 81, 88, 0.25);
  border-top-color: #535158;
}

.size-md {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 3px;
  transform-origin: 50% 50% calc((1.5rem / 2) + 3px);
}

.size-sm {
  width: 1rem;
  height: 1rem;
  border-width: 2px;
  transform-origin: 50% 50% calc((1rem / 2) + 2px);
}

.theme-dark {
  border-color: rgba(83, 81, 88, 0.25);
  border-top-color: #535158;
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}