.root {
  position: fixed;
  z-index: 10;
  overflow-y: auto;
  background-color: #848a97;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.root>div {
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  box-sizing: border-box;
}

.container {
  position: relative;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  overflow: hidden;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  text-align: left;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-duration: 150ms;
}

@media (min-width: 640px) {
  .root>div {
    align-items: center;
    padding: 0px;
  }

  .container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 32rem;
  }
}