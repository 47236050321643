.input {
  tab-size: 8;
  box-sizing: border-box;
  font-variant-ligatures: none;
  margin: 0;
  overflow: visible;
  display: block;
  width: 100%;

  background-clip: padding-box;
  border: 1px solid #89888d;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 4px;
  background-color: #fff;
  font-size: 0.875rem;
  line-height: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  color: #333238;
  box-shadow: inset 0 0 0 1px #89888d;
  border-style: none;
  height: 32px;
  outline: none;
}

.input:disabled {
  background-color: #fbfafd;
  box-shadow: inset 0 0 0 1px #dcdcde;
}

.input:hover:not(:disabled) {
  box-shadow: inset 0 0 0 2px #064787, 0 2px 2px 0 rgba(0, 0, 0, 0.08);
}

.input:focus:not(:disabled) {
  box-shadow: inset 0 0 0 1px #064787, 0 0 0 1px #fff, 0 0 0 3px #428fdc;
}

.input:active:not(:disabled) {
  box-shadow: inset 0 0 0 1px #033464, 0 0 0 1px #fff, 0 0 0 3px #428fdc;
}