.form-group {
  margin-bottom: 1rem;
}

.form-group.error input {
  border: 1px solid #dd2b0e;
}

.form-group .field-error {

  line-height: 1.5;
  text-align: left;
  tab-size: 8;
  box-sizing: border-box;
  font-variant-ligatures: none;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #dd2b0e;
  font-size: 0.875rem;
}

.form-group.error .field-error {
  display: block;
}

.form-group small {
  text-align: left;
  tab-size: 8;
  box-sizing: border-box;
  font-variant-ligatures: none;

  display: block;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #737278;
}

.form-group>label {
  width: 100%;
  line-height: 1.5;
  color: #333238;
  text-align: left;
  font-size: 0.875rem;
  tab-size: 8;
  box-sizing: border-box;
  font-variant-ligatures: none;
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.form-group .input:focus {
  box-shadow: inset 0 0 0 1px #333238, 0 0 0 1px #fff, 0 0 0 3px #428fdc;
  color: #333238;
}