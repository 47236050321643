.link {
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
  cursor: pointer;
}

.link:hover {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}