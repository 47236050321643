.input-file input {
  display: none !important;
  visibility: hidden !important;
}

.input-file label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.input-file label span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: -1px;
  margin-bottom: -1px;
}