.body {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-top: 1.25rem;
}

@media (min-width: 640px) {
  .body {
    padding: 1.5rem;
    padding-bottom: 1rem;
  }
}