.avatar-text {
  display: inline-flex;
  overflow: hidden;
  flex-shrink: 0;
  text-align: center;
  vertical-align: top;
  justify-content: center;
  align-items: center;
}

/* theme: default : */

.avatar-text.theme-default {
  background-color: #ecf4ee;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(31, 30, 36, 0.08);
  color: #333238;
}

/* size: md : */

.avatar-text.size-md {
  width: 3rem;
  height: 3rem;
  font-size: 1.1875rem;
  line-height: 1.5rem;
  border-radius: 0.5rem;
}

.avatar-text.size-lg {
  width: 6rem;
  height: 6rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  border-radius: 0.8rem;
}