@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap'); */
* {
  font-family: BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, " Helvetica Neue", sans-serif, " Apple Color Emoji", " Segoe UI Emoji", " Segoe UI Symbol", " Noto Color Emoji";
  font-variant-ligatures: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333238;
  text-align: left;
}

body {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

/* hr: */

hr {
  overflow: hidden;
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #ececef;
}

/* rows: */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%
}

.row-cols-1>* {
  flex: 0 0 100%;
  max-width: 100%
}

.row-cols-2>* {
  flex: 0 0 50%;
  max-width: 50%
}

.row-cols-3>* {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%
}

.row-cols-4>* {
  flex: 0 0 25%;
  max-width: 25%
}

.row-cols-5>* {
  flex: 0 0 20%;
  max-width: 20%
}

.row-cols-6>* {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%
}

.order-first {
  order: -1
}

.order-last {
  order: 13
}

.order-0 {
  order: 0
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.order-3 {
  order: 3
}

.order-4 {
  order: 4
}

.order-5 {
  order: 5
}

.order-6 {
  order: 6
}

.order-7 {
  order: 7
}

.order-8 {
  order: 8
}

.order-9 {
  order: 9
}

.order-10 {
  order: 10
}

.order-11 {
  order: 11
}

.order-12 {
  order: 12
}

.offset-1 {
  margin-left: 8.33333333%
}

.offset-2 {
  margin-left: 16.66666667%
}

.offset-3 {
  margin-left: 25%
}

.offset-4 {
  margin-left: 33.33333333%
}

.offset-5 {
  margin-left: 41.66666667%
}

.offset-6 {
  margin-left: 50%
}

.offset-7 {
  margin-left: 58.33333333%
}

.offset-8 {
  margin-left: 66.66666667%
}

.offset-9 {
  margin-left: 75%
}

.offset-10 {
  margin-left: 83.33333333%
}

.offset-11 {
  margin-left: 91.66666667%
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .row-cols-sm-1>* {
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-sm-2>* {
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-sm-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .row-cols-sm-4>* {
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-sm-5>* {
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-sm-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-sm-first {
    order: -1
  }

  .order-sm-last {
    order: 13
  }

  .order-sm-0 {
    order: 0
  }

  .order-sm-1 {
    order: 1
  }

  .order-sm-2 {
    order: 2
  }

  .order-sm-3 {
    order: 3
  }

  .order-sm-4 {
    order: 4
  }

  .order-sm-5 {
    order: 5
  }

  .order-sm-6 {
    order: 6
  }

  .order-sm-7 {
    order: 7
  }

  .order-sm-8 {
    order: 8
  }

  .order-sm-9 {
    order: 9
  }

  .order-sm-10 {
    order: 10
  }

  .order-sm-11 {
    order: 11
  }

  .order-sm-12 {
    order: 12
  }

  .offset-sm-0 {
    margin-left: 0
  }

  .offset-sm-1 {
    margin-left: 8.33333333%
  }

  .offset-sm-2 {
    margin-left: 16.66666667%
  }

  .offset-sm-3 {
    margin-left: 25%
  }

  .offset-sm-4 {
    margin-left: 33.33333333%
  }

  .offset-sm-5 {
    margin-left: 41.66666667%
  }

  .offset-sm-6 {
    margin-left: 50%
  }

  .offset-sm-7 {
    margin-left: 58.33333333%
  }

  .offset-sm-8 {
    margin-left: 66.66666667%
  }

  .offset-sm-9 {
    margin-left: 75%
  }

  .offset-sm-10 {
    margin-left: 83.33333333%
  }

  .offset-sm-11 {
    margin-left: 91.66666667%
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .row-cols-md-1>* {
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-md-2>* {
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-md-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .row-cols-md-4>* {
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-md-5>* {
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-md-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-md-first {
    order: -1
  }

  .order-md-last {
    order: 13
  }

  .order-md-0 {
    order: 0
  }

  .order-md-1 {
    order: 1
  }

  .order-md-2 {
    order: 2
  }

  .order-md-3 {
    order: 3
  }

  .order-md-4 {
    order: 4
  }

  .order-md-5 {
    order: 5
  }

  .order-md-6 {
    order: 6
  }

  .order-md-7 {
    order: 7
  }

  .order-md-8 {
    order: 8
  }

  .order-md-9 {
    order: 9
  }

  .order-md-10 {
    order: 10
  }

  .order-md-11 {
    order: 11
  }

  .order-md-12 {
    order: 12
  }

  .offset-md-0 {
    margin-left: 0
  }

  .offset-md-1 {
    margin-left: 8.33333333%
  }

  .offset-md-2 {
    margin-left: 16.66666667%
  }

  .offset-md-3 {
    margin-left: 25%
  }

  .offset-md-4 {
    margin-left: 33.33333333%
  }

  .offset-md-5 {
    margin-left: 41.66666667%
  }

  .offset-md-6 {
    margin-left: 50%
  }

  .offset-md-7 {
    margin-left: 58.33333333%
  }

  .offset-md-8 {
    margin-left: 66.66666667%
  }

  .offset-md-9 {
    margin-left: 75%
  }

  .offset-md-10 {
    margin-left: 83.33333333%
  }

  .offset-md-11 {
    margin-left: 91.66666667%
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .row-cols-lg-1>* {
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-lg-2>* {
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-lg-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .row-cols-lg-4>* {
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-lg-5>* {
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-lg-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-lg-first {
    order: -1
  }

  .order-lg-last {
    order: 13
  }

  .order-lg-0 {
    order: 0
  }

  .order-lg-1 {
    order: 1
  }

  .order-lg-2 {
    order: 2
  }

  .order-lg-3 {
    order: 3
  }

  .order-lg-4 {
    order: 4
  }

  .order-lg-5 {
    order: 5
  }

  .order-lg-6 {
    order: 6
  }

  .order-lg-7 {
    order: 7
  }

  .order-lg-8 {
    order: 8
  }

  .order-lg-9 {
    order: 9
  }

  .order-lg-10 {
    order: 10
  }

  .order-lg-11 {
    order: 11
  }

  .order-lg-12 {
    order: 12
  }

  .offset-lg-0 {
    margin-left: 0
  }

  .offset-lg-1 {
    margin-left: 8.33333333%
  }

  .offset-lg-2 {
    margin-left: 16.66666667%
  }

  .offset-lg-3 {
    margin-left: 25%
  }

  .offset-lg-4 {
    margin-left: 33.33333333%
  }

  .offset-lg-5 {
    margin-left: 41.66666667%
  }

  .offset-lg-6 {
    margin-left: 50%
  }

  .offset-lg-7 {
    margin-left: 58.33333333%
  }

  .offset-lg-8 {
    margin-left: 66.66666667%
  }

  .offset-lg-9 {
    margin-left: 75%
  }

  .offset-lg-10 {
    margin-left: 83.33333333%
  }

  .offset-lg-11 {
    margin-left: 91.66666667%
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .row-cols-xl-1>* {
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-xl-2>* {
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-xl-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .row-cols-xl-4>* {
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-xl-5>* {
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-xl-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%
  }
}