.eventItem {
  font-size: 0.875rem;
  padding: 1rem 0;
  border-bottom: 1px solid #ececef;
  color: #737278;
  position: relative;
  line-height: 1.25rem;
  display: grid;
  grid-template-columns: 48px 1fr auto;
  gap: 1rem;
}

.eventItem:last-child {
  border-bottom: 0 !important;
}

.content {}

.content .body {
  margin-bottom: 4px;
}

.content .body span {
  font-weight: 500;
  font-size: 0.875rem;
}

.content .info {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 0.25rem;
  align-items: center;
}

.content .info span {
  font-size: 0.875rem;
  color: #737278;
}

/* status: */

.status {
  padding: 0.25rem;
  width: 1rem;
  height: 1rem;
  border-radius: 9999px;
  display: inline-block;
  margin-right: .25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.status>div {
  border-radius: 9999px;
  width: 0.375rem;
  height: 0.375rem;
}


/* state active: */

.status.state-active {
  background-color: rgb(16 185 129 / 0.2);
  animation-name: ping;
  animation-duration: 1s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
}

.status.state-active>div {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}


/* state inactive: */

.status.state-inactive {
  background-color: rgb(254 226 226 / 0.2);
}

.status.state-inactive>div {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

@media (max-width: 640px) {
  .eventItem {
    user-select: none;
  }
}

@keyframes ping {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 100%;
  }

  100% {
    opacity: 0;
  }
}