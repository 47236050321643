.button {
  box-sizing: border-box;
  font-variant-ligatures: none;
  margin: 0;
  font-family: inherit;
  overflow: visible;
  text-transform: none;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  display: inline-flex;
  border-color: #dcdcde;
  white-space: nowrap;
  border-width: 0;
  fill: currentColor;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  transition: background-color 100ms linear, border-color 100ms linear, color 100ms linear, box-shadow 100ms linear;
}

.button>.button-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: -1px;
  margin-bottom: -1px;
  font-size: inherit;
}

.btn.size-sm {
  line-height: 1rem;
  border-radius: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  line-height: 1rem;
  font-size: 0.875rem !important;
}

.btn.size-md {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  line-height: 1rem;
  border-radius: 0.25rem;
  font-size: 0.875rem !important;
}

.button:not(:disabled) {
  cursor: pointer;
}


/* theme: primary: */

.btn.theme-primary {
  color: #fff;
  background-color: #1f75cb;
  box-shadow: inset 0 0 0 1px #1068bf;
}

.btn.theme-primary:not(:disabled):hover {
  box-shadow: inset 0 0 0 2px #064787, 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  background: #1068bf;
}

.btn.theme-primary:not(:disabled):focus {
  box-shadow: inset 0 0 0 1px #064787, 0 0 0 1px #fff, 0 0 0 3px #428fdc;
  outline: none;
  background-color: #1068bf;
}

.btn.theme-primary:not(:disabled):active {
  box-shadow: inset 0 0 0 1px #033464, 0 0 0 1px #fff, 0 0 0 3px #428fdc;
  outline: none;
  background-color: #0b5cad;
}

/* theme: primary-text: */

.btn.theme-primary-text {
  color: #1f75cb;
  text-decoration: none;
  background-color: transparent;
}

.btn.theme-primary-text:not(:disabled):hover {
  color: #1f75cb;
  text-decoration: underline;
}

.btn.theme-primary-text:not(:disabled):focus {
  text-decoration: underline;
}

.btn.theme-primary-text:not(:disabled):active {
  text-decoration: underline;
}

/* theme: default: */

.btn.theme-default {
  color: #333238;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #bfbfc3;
}

.btn.theme-default:not(:disabled):hover {
  box-shadow: inset 0 0 0 2px #89888d, 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  background: #ececef;
}

.btn.theme-default:not(:disabled):focus {
  box-shadow: inset 0 0 0 1px #89888d, 0 0 0 1px #fff, 0 0 0 3px #428fdc;
  outline: none;
  background-color: #ececef;
}

.btn.theme-default:not(:disabled):active {
  box-shadow: inset 0 0 0 1px #626168, 0 0 0 1px #fff, 0 0 0 3px #428fdc;
  outline: none;
  background-color: #dcdcde;
}

/* theme: tertiary: */
.btn.theme-tertiary {
  color: transparent;
  background-color: transparent;
}

/* focus: */

.button:focus:not(.theme-tertiary) {
  box-shadow: inset 0 0 0 1px #064787, 0 0 0 1px #fff, 0 0 0 3px #428fdc;
}

.button:disabled {
  background-color: #fbfafd;
  color: #737278;
  box-shadow: inset 0 0 0 1px #dcdcde;
  cursor: not-allowed !important;
  text-decoration: none;
  opacity: 0.65;
}

/* has icon: */
.size-sm.btn-icon {
  padding: 0.25rem !important;
  line-height: 1rem;
}

.size-md.btn-icon {
  padding: 0.5rem !important;
  line-height: 1rem;
}