.button {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  font-family: inherit;
  margin: 0;
  text-transform: none;
  cursor: pointer;
  background-image: none;
  display: inline-flex;
  justify-content: center;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  /* --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); */
  user-select: none;
}

@media (max-width: 640px) {
  .button {
    user-select: none;
  }
}


/* theme primary: */

.theme-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.theme-primary:not(:disabled):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.theme-primary:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 2px;
  outline-color: #4f46e5;
}

.theme-primary:disabled {
  --tw-bg-opacity: .5;
}


/* theme border-gray: */

.theme-border-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  color: rgb(17 24 39 / var(--tw-text-opacity));
  border-width: 1px;
  border-color: rgb(229, 231, 235);
}

.theme-border-gray:not(:disabled):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.theme-border-gray:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 0px;
  outline-color: #000;
}

.theme-border-gray:disabled {
  --tw-bg-opacity: .5;
  --tw-text-opacity: .5;
}


/* theme danger: */

.theme-danger {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.theme-danger:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.theme-danger:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 2px;
  outline-color: rgb(239 68 68);
}

.theme-danger:disabled {
  --tw-bg-opacity: .5;
}