.holder {
  margin: 0.75rem 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-start
}

.title {
  color: #333238;
  font-size: 1.75rem;
  margin: 0;
  line-height: 1.3;
}

.actions {
  gap: 1rem;
  align-items: center;
  display: flex;
}

@media (max-width: 767.98px) {
  .holder {
    flex-direction: column;
  }

  .title {
    margin: 0.75rem 0;
  }
}